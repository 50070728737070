/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

import '../css/footer.css';

function Footer() {
  return (
    <div className="parent footerP">
      <div className="child footerC">
        <div className="newsletter">
          <div className="title">Subscribe to Newsletter</div>
          <form className="ffffnewsletter">
            <i className="fal fa-envelope" />
            <input
              type="text"
              name="search"
              placeholder="Enter Email address"
            />
            <button type="submit">Submit</button>
          </form>
        </div>

        <footer>
          <div className="aSec">
            <img
              src="/images/pavi.webp"
              style={{ width: '200px' }}
              alt="logo"
            />
            <div className="social">
              <a href="#">
                <i className="fab fa-linkedin-in" />
              </a>
            </div>
          </div>

          <div className="bSec">
            <div className="linkTitle">
              Quick Links <hr />
            </div>
            <div className="links">
              <a href="/#how-it-works">How it Works</a>
              <Link to="/about">About</Link>
              <Link to="/request-demo">Request demo</Link>
              <Link to="/login">Login</Link>
            </div>
          </div>

          <div className="bSec">
            <div className="linkTitle">
              Contact Us <hr />
            </div>
            <div className="links">
              <div className="a1 llll">
                <div className="a1Icon">
                  <i className="fal fa-envelope" />
                </div>
                <a href="mailto:info@pavi.ai" className="hmmmm">
                  info@pavi.ai
                </a>
              </div>
            </div>
          </div>
        </footer>

        <div className="lastFo">
          <p>2023 AnonyDoxx, LLC All Rights Reserved.</p>
          <div>
            <a href="#">Privacy Policy</a>
            <a href="#">Terms & Conditions</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
