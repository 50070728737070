import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Footer from './components/Footer';
import Header from './components/Header';
import About from './pages/About';
import Home from './pages/Home';
import Login from './pages/login';
import RequestDemo from './pages/RequestDemo';
import Reset from './pages/Reset';
import UserRegistration from './pages/UserRegistration';

function App() {
  return (
    <BrowserRouter>
      <Header />

      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/request-demo" exact component={RequestDemo} />
        <Route path="/login" exact component={Login} />
        <Route path="/reset" exact component={Reset} />
        <Route path="/register" exact component={UserRegistration} />
      </Switch>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
