/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import '../css/header.css';

function Header() {
  const [open, setopen] = useState();

  function openit() {
    setopen('nav-opened');
  }

  function closeit() {
    setopen('');
  }

  function linkClicked() {
    setopen('');
  }
  return (
    <div className="header">
      <div className="child-header">
        <div className="logo">
          <Link to="/">
            <img src="/images/pavi.webp" alt="Logo" />
          </Link>
        </div>
        <nav className={open}>
          <div className="navLinks">
            <a href="/#how-it-works" onClick={linkClicked}>
              How it Works
            </a>
            <Link to="/about" onClick={linkClicked}>
              About
            </Link>
            <Link to="/request-demo" onClick={linkClicked}>
              Request demo
            </Link>
            <Link to="/login" onClick={linkClicked}>
              Login
            </Link>
          </div>
          <div className="close" onClick={closeit}>
            <i className="far fa-times" />
          </div>
        </nav>
        <div className="burger" onClick={openit}>
          <i className="far fa-bars" />
        </div>
      </div>
    </div>
  );
}

export default Header;
