/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { useLocation } from 'react-router-dom';

import '../css/about.css';

function About() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  React.useEffect(() => {
    onTop();
  }, [routePath]);

  return (
    <div className="parent ">
      <div className="child">
        <div className="about-main-div">
          <div className="maintext-about">
            <div>An exceptional experience</div>
            <span>
              PAVI accelerates and streamlines your hiring process with
              automated interviews powered by AI. Supporting organizations of
              any size, across industries and globally, we make it possible to
              hire great people anytime, anywhere and on any device.
              <br />
              <br />
              We help our clients capitalize on a tailored recruitment process
              that delivers high-quality efficient results. Our primary focus
              has always been on the candidate experience, providing valuable
              and transparent feedback at every step of their journey
            </span>
          </div>
          <video src="/images/PAVI_V.mp4" controls />
        </div>
      </div>
    </div>
  );
}

export default About;
