/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import { Link } from 'react-router-dom';

import '../css/home.css';
import 'react-alice-carousel/lib/alice-carousel.css';

function Home() {
  const responsive = {
    0: { items: 1 },
    600: { items: 2 },
    837: { items: 3 },
    1024: { items: 4 },
  };

  const items = [
    <div className="caresoulLogo">
      <img
        src="/images/image 1 (2).png"
        style={{ maxWidth: '180px' }}
        alt=""
        draggable="false"
      />
    </div>,
    <div className="caresoulLogo">
      <img
        src="/images/image 2.svg"
        style={{ height: '100px' }}
        alt=""
        draggable="false"
      />
    </div>,
    <div className="caresoulLogo">
      <img
        src="/images/E5FD154C-7609-4B81-97D1-DC9D7E7CD19F.png"
        style={{ marginTop: '10px' }}
        alt=""
        draggable="false"
      />
    </div>,
    <div className="caresoulLogo">
      <img
        src="/images/Guava OG (1).png"
        alt=""
        style={{ maxWidth: '140px' }}
        draggable="false"
      />
    </div>,
  ];

  return (
    <>
      <div className="parent a-secP">
        <img src="/images/plusCircle.png" alt="" className="circle2" />

        <div className="a-secC child">
          <div className="aSecTxt">
            <h3>
              Automated
              <br />
              Video <span>Intelligence</span>
            </h3>
            <p>Recruitment Automation Realized</p>
            <div className="asecLinks">
              <Link to="/request-demo">Request Demo</Link>
              <Link to="/about">
                <img src="/images/play.png" alt="" /> WATCH VIDEO
              </Link>
            </div>
          </div>

          <div className="asecImages">
            <img src="/images/main.webp" alt="" className="mainImage" />
          </div>
        </div>
      </div>

      <div className="parent caresoulP">
        <div className="child caresoulC">
          <AliceCarousel
            mouseTracking
            items={items}
            responsive={responsive}
            controlsStrategy="default"
            infinite
            disableDotsControls
            autoPlay
            disableButtonsControls
            autoPlayInterval={1000}
            animationDuration={2000}
          />
        </div>
      </div>

      <div id="how-it-works" className="parent circularPartP">
        <div className="child circularPartC">
          <div className="circTitle">
            <h3>How it Works</h3>
            <p>
              PAVI automates the talent lifecycle allowing employers to focus on
              business objectives with full-visibility into the talent
              acquisition process.
            </p>

            <p style={{ marginTop: '20px' }}>
              The pavi platform gives users insight and transparency around the
              interview process while providing real-time feedback on
              communication.
            </p>
          </div>

          <div className="realCircularFlex">
            <div className="circularImage">
              <img src="/images/logoSingle.svg" alt="" />
            </div>

            <div className="circularText">
              <div className="circT1 circT2">
                <div className="iconCirc iconCirc1">
                  <img src="/images/icon1.png" alt="" />
                </div>
                <div className="iii iii1">
                  <h3>Invitation</h3>
                  <p>
                    You will receive an invitation to create a user account.
                    simply follow the steps to create the account and login to
                    the pavi platform.
                  </p>
                </div>
              </div>

              <div className="circT1 circT12">
                <div className="iconCirc iconCirc2">
                  <img src="/images/icon2.png" alt="" />
                </div>
                <div className="iii iii2">
                  <h3>Get Started</h3>
                  <p>
                    Once logged in, you will be able to view the job you have
                    been invited to apply to. click “get started” to begin the
                    interview process.
                  </p>
                </div>
              </div>

              <div className="circT1 circT12">
                <div className="iconCirc iconCirc3">
                  <img src="/images/icon3.png" alt="" />
                </div>
                <div className="iii iii3">
                  <h3>Create Profile</h3>
                  <p>
                    You will be prompted with questions that are specific to the
                    job you are interviewing for. please watch the timer, each
                    question has a set time for your response!
                  </p>
                </div>
              </div>

              <div className="circT1">
                <div className="iconCirc iconCirc4">
                  <img
                    src="/images/finish.svg"
                    style={{ marginTop: '7px' }}
                    alt=""
                  />
                </div>
                <div className="iii iii4">
                  <h3>That's it !</h3>
                  <p>
                    You've completed the pavi process! your interview is now
                    available to be reviewed on your dashboard.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="/images/border.png" alt="" className="jjjj" />
      </div>

      <div className="parent simpleP">
        <div className="child simpleC">
          <div className="simpleText">
            <h3 className="aboutTextHome">Some words about us</h3>
            <p className="aboutTextHome">
              PAVI is a predictive automated video intelligence SaaS platform
              for global employers. We’re on a mission to intertwine talent
              acquisition with end-to-end, technology-enabled, human-first
              workforce capabilities. Under our shared-services umbrella we
              offer a comprehensive recruitment talent suite for hyper-growth
              companies.
            </p>
          </div>

          <div className="simpleImg">
            <img src="/images/simple.png" alt="" />
          </div>
        </div>

        <img src="/images/border2.png" alt="" className="jjjj" />
      </div>
    </>
  );
}

export default Home;
