import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import MultiRangeSlider from '../components/Range';

import '../css/requestdemo.css';

function RequestDemo() {
  const [hiringFrom, sethiringFrom] = React.useState();
  const [hiringTo, sethiringTo] = React.useState();

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  React.useEffect(() => {
    onTop();
  }, [routePath]);

  return (
    <div className="parent ">
      <div className="child">
        <div className="mainRequest" style={{ position: 'relative' }}>
          <div className="titleReq">Request Demo</div>

          <form action="https://formsubmit.co/jordan@pavi.ai" method="POST">
            <input
              type="hidden"
              name="_next"
              value="https://pavi.ai/request-demo"
            />
            <input type="hidden" name="_subject" value="Request Demo" />
            <input type="hidden" name="_captcha" value="false" />
            <div className="inputDiv">
              <input
                type="text"
                required
                name="Company Name"
                placeholder="Company Name"
              />
            </div>
            <div className="inputDiv">
              <img src="/images/pin1.png" className="pin" alt="icon" />
              <input
                type="text"
                required
                name="Company Address"
                placeholder="Company Address"
              />
            </div>
            <div className="inputGrid">
              <div className="inputDiv">
                <input
                  type="text"
                  required
                  name="Contact Person"
                  placeholder="Contact Person"
                />
              </div>
              <div className="inputDiv">
                <img src="/images/phone_call.png" alt="icon" />
                <input
                  type="text"
                  required
                  name="Contact Number"
                  placeholder="Contact Number"
                />
              </div>
            </div>
            <div className="inputDiv">
              <img src="/images/email_icon.png" alt="icon" />
              <input type="email" required name="Email" placeholder="Email" />
            </div>
            <div className="inputDiv">
              <img src="/images/country_icon.png" alt="icon" />
              <input
                type="text"
                required
                name="Company Website"
                placeholder="Company Website"
              />
            </div>
            <div>
              <div
                style={{
                  fontSize: '18px',
                  fontWeight: '700',
                  marginBottom: '20px',
                }}>
                Number of positions hiring
              </div>
              <MultiRangeSlider
                min={0}
                max={30}
                onChange={({ min, max }) => {
                  sethiringFrom(min);
                  sethiringTo(max);
                }}
              />
              <input
                type="number"
                name="Hiring From"
                className="hidden"
                value={hiringFrom}
              />
              <input
                type="number"
                name="Hiring To"
                className="hidden"
                value={hiringTo}
              />
            </div>
            <button type="submit" className="buttonReq">
              Request A Demo
            </button>
            <Link to="/" className="cancelReq">
              Cancel
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RequestDemo;
