/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../css/login.css';

function Login() {
  const [applicant, setapplicant] = React.useState('e');
  const [employer, setemployer] = React.useState('hidden');

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  React.useEffect(() => {
    onTop();
  }, [routePath]);

  return (
    <div className="parent ">
      <div className="child">
        <div className="mainRequest">
          <div className="titleLog">ENTER THE PLATFORM AS</div>

          <div className="categoryFlex">
            <button
              type="button"
              onClick={() => {
                setapplicant('');
                setemployer('hidden');
              }}
              className={applicant === 'hidden' ? '' : 'buttonActive'}>
              APPLICANT
            </button>
            <button
              type="button"
              onClick={() => {
                setapplicant('hidden');
                setemployer('');
              }}
              className={employer === 'hidden' ? '' : 'buttonActive'}>
              EMPLOYER
            </button>
          </div>

          <div className={applicant}>
            <form action="https://formsubmit.co/jordan@pavi.ai" method="POST">
              <input type="hidden" name="_next" value="https://pavi.ai/login" />
              <input type="hidden" name="_subject" value="Login Applicant" />
              <input type="hidden" name="_captcha" value="false" />
              <div className="inputDiv">
                <input
                  type="text"
                  required
                  name="Username"
                  placeholder="Username"
                />
              </div>
              <div className="inputDiv">
                <input
                  type="password"
                  required
                  name="Password"
                  placeholder="Password"
                />
                <Link to="/reset">Recover</Link>
              </div>
              <button type="submit" className="buttonReq">
                Sign In
              </button>

              <div className="or">OR</div>

              <div className="notAccount">
                Don't have an account yet? <Link to="/register">Sign Up</Link>
              </div>
            </form>
          </div>

          <div className={employer}>
            <form action="https://formsubmit.co/jordan@pavi.ai" method="POST">
              <input type="hidden" name="_next" value="https://pavi.ai/login" />
              <input type="hidden" name="_subject" value="Login Employer" />
              <input type="hidden" name="_captcha" value="false" />
              <div className="inputDiv">
                <input
                  type="text"
                  required
                  name="Username"
                  placeholder="Username"
                />
              </div>
              <div className="inputDiv">
                <input
                  type="password"
                  required
                  name="Password"
                  placeholder="Password"
                />
              </div>
              <button type="submit" className="buttonReq">
                Sign In
              </button>
            </form>
            <div className="or">OR</div>

            <div className="notAccount">
              Nedd Employer Access{' '}
              <Link to="/request-demo">Request A Demo</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
