import React from 'react';
import { useLocation } from 'react-router-dom';

import '../css/login.css';

function Reset() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  React.useEffect(() => {
    onTop();
  }, [routePath]);

  return (
    <div className="parent ">
      <div className="child">
        <div className="mainRequest">
          <div className="titleLog">Reset Password</div>

          <div>
            <form action="https://formsubmit.co/jordan@pavi.ai" method="POST">
              <input type="hidden" name="_next" value="https://pavi.ai/reset" />
              <input type="hidden" name="_subject" value="Reset Password" />
              <input type="hidden" name="_captcha" value="false" />
              <div className="inputDiv">
                <img src="/images/email_icon.png" alt="icon" />
                <input type="email" required name="Email" placeholder="Email" />
              </div>
              <button type="submit" className="buttonReq">
                Send Email
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reset;
